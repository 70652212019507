<template>
    <v-container>
        <v-breadcrumbs :items="breadCombs" class="d-print-none"></v-breadcrumbs>

        <v-divider class="mx-4 d-print-none"></v-divider>
        <v-row class="d-print-none">
            <v-col cols="12" sm="12" md="12">
                <v-row>
                    <v-divider
                            class="mx-4"
                            vertical
                    ></v-divider>
                    <v-col cols="12" sm="2" md="2">
                        <v-select
                                v-model="selectId"
                                :items="printSettings"
                                :label="$vuetify.lang.t('$vuetify.printSetting.title')"
                                @change="selectChange"
                        ></v-select>
                    </v-col>
                    <v-divider
                            class="mx-4"
                            vertical
                    ></v-divider>
                    <v-col cols="12" sm="2" md="1">
                        <v-text-field
                                :label="$vuetify.lang.t('$vuetify.printSetting.left')"
                                v-model="printSetting.left"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <v-text-field
                                :label="$vuetify.lang.t('$vuetify.printSetting.right')"
                                v-model="printSetting.right"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <v-text-field
                                :label="$vuetify.lang.t('$vuetify.printSetting.top')"
                                v-model="printSetting.top"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <v-text-field
                                :label="$vuetify.lang.t('$vuetify.printSetting.bottom')"
                                v-model="printSetting.bottom"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <v-text-field
                                :label="$vuetify.lang.t('$vuetify.printSetting.qrSize')"
                                v-model="printSetting.qrSize"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <v-text-field
                                :label="$vuetify.lang.t('$vuetify.printSetting.fontSize')"
                                v-model="printSetting.fontSize"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                        <v-text-field
                                :label="$vuetify.lang.t('$vuetify.printSetting.cols')"
                                v-model="printSetting.cols"
                                readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="d-print-none">
            <v-col cols="12" sm="12" md="8">
                <v-btn-toggle>
                    <v-dialog v-model="dialog" max-width="290">
                        <v-card>
                            <v-card-title class="headline">{{$vuetify.lang.t('$vuetify.qrCode.genQr')}}</v-card-title>
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="12" sm="12" md="11">
                                        <v-text-field
                                                v-model="addNum"
                                                :label="$vuetify.lang.t('$vuetify.qrCode.batchNum')"
                                                :error-messages="errorMessage"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="11">
                                        <v-text-field
                                                v-model="mark"
                                                :label="$vuetify.lang.t('$vuetify.qrCode.mark')"

                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="11">
                                        <input type="checkbox" v-model="encrypt"> {{$vuetify.lang.t('$vuetify.qrCode.encrypt')}}
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                            <v-btn color="green darken-1" text @click.stop="dialog=false">{{$vuetify.lang.t('$vuetify.base.cancel')}}</v-btn>
                            <v-btn color="green darken-1" text @click.stop="batchGenNo">{{$vuetify.lang.t('$vuetify.base.confirm')}}</v-btn>
                        </v-card>
                    </v-dialog>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-divider
                class="d-print-none"
        ></v-divider>
        <v-row justify="space-between" class="mx-4 d-print-none">
            <v-col cols="12" sm="12" md="2">
                <v-text-field
                        v-model="searchMark"
                        :label="$vuetify.lang.t('$vuetify.qrCode.mark')"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="2">
                <v-text-field
                        v-model="categoryPath"
                        :label="$vuetify.lang.t('$vuetify.qrCode.categoryPath')"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-select
                        v-model="searchUsed"
                        :items="$vuetify.lang.t('$vuetify.qrCode.uses')"
                        :label="$vuetify.lang.t('$vuetify.qrCode.isUsed')"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-select
                        v-model="printed"
                        :items="$vuetify.lang.t('$vuetify.qrCode.prints')"
                        :label="$vuetify.lang.t('$vuetify.qrCode.isPrinted')"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mt-6">
                <v-btn small class="primary white--text mr-2" @click.stop="search"><strong>{{$vuetify.lang.t('$vuetify.base.search')}}</strong></v-btn>
                <v-btn small class="indigo white--text mr-4" @click.stop="dialog=true">{{$vuetify.lang.t('$vuetify.qrCode.batchGenQr')}}</v-btn>
                <v-btn small v-print="printObj" class="primary white--text ml-6"><strong>{{$vuetify.lang.t('$vuetify.printSetting.print')}}</strong></v-btn>
                <v-btn small class="primary white--text ml-2" @click.stop="markedPrinted"><strong>{{$vuetify.lang.t('$vuetify.qrCode.markPrinted')}}</strong>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" :md="12/printSetting.cols" v-for="(qrCode, index) in qrCodes" :key="index"
                   :style="{paddingTop:printSetting.top + 'px',paddingRight:printSetting.right + 'px',paddingBottom:printSetting.bottom + 'px',paddingLeft:printSetting.left + 'px',fontSize: printSetting.fontSize + 'pt' }">
                <div style="display:flex;flex-direction:row">
                    <div :style="{width:printSetting.qrSize + 'px'}" style="position:relative;clear:both">
                        <img src="../../assets/logo.png"
                             :style="{width:printSetting.qrSize/5 + 'px',marginTop:printSetting.qrSize/10*4 + 'px',marginLeft:printSetting.qrSize/10*4 + 'px'}"
                             style="position:absolute;clear:both"/>
                        <qriously
                                :value="qrCode.codeInfo"
                                :size="printSetting.qrSize"
                        />
                    </div>
                    <div style="margin-left:10px">
                        <div class="red--text d-print-none">{{qrCode.used ? $vuetify.lang.t('$vuetify.qrCode.used') :$vuetify.lang.t('$vuetify.qrCode.unUsed')}}</div>
                        <div v-if="qrCode.categoryPath!=null">{{qrCode.categoryPath}}</div>
                        <div v-if="qrCode.mark!=null">{{qrCode.mark}}</div>
                    </div>
                </div>

            </v-col>
        </v-row>

        <v-row justify="center" class="d-print-none">
            <v-col cols="8">
                <v-container>
                    <v-pagination
                            v-model="pageNo"
                            class="my-4"
                            :length="totalPage"
                            @input="changeNo"
                    ></v-pagination>
                </v-container>
            </v-col>
            <v-col cols="1" class="my-4">
                <v-select
                        v-model="pageSize"
                        :items="pageSizes"
                        @change="pageSizeChange"
                ></v-select>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>
    import {mapActions} from "vuex";

    export default {
        data: () => ({
            dialog: false,
            pageSize: 10,
            pageSizes: [10, 20, 100],
            printSetting: {},
            printSettings: [],
            printRawData: [],
            selectId: "",
            errorMessage: '',
            addNum: 100,
            encrypt: false,
            mark: "BML",
            printObj: {
                id: "printMe",
                popTitle: 'QR',
            },
            items: [],
            qrCodes: [],
            pageNo: 1,
            totalPage: 1,
            codeTypes: ["NORMAL", "ENCRYPTED"],
            codeType: "NORMAL",
            searchUsed: "ALL",
            printed: "ALL"
        }),
        created() {
            this.hideDrawer();
            this.getData();
        },
        computed: {
            breadCombs() {
                return [
                    {
                        text: this.$vuetify.lang.t('$vuetify.base.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: this.$vuetify.lang.t('$vuetify.base.qrCode'),
                        disabled: true,
                        href: '#/qrcoe',
                    }
                ]
            }
        },
        methods: {
            ...mapActions('navigation', ["hideDrawer"]),
            getData() {
                this.$axios.get(
                    "printSetting",
                    {}
                ).then((response) => {
                    this.printRawData = response.data.data;
                    let printSettings = [];
                    let j = 0
                    for (; j < this.printRawData.length; j++) {
                        let print = this.printRawData[j];
                        printSettings.push({
                            "text": print.name,
                            "value": print.id
                        });
                    }
                    this.printSettings = printSettings;
                    this.printSetting = JSON.parse(this.printRawData[0].print);
                    this.selectId = this.printRawData[0].id;
                }).catch((error) => {
                    console.error(error)
                });

                let used = null;
                if (this.searchUsed == "USED") {
                    used = true;
                }
                if (this.searchUsed == "UNUSED") {
                    used = false;
                }
                let printed = null;
                if (this.printed == "PRINTED") {
                    printed = true;
                }
                if (this.printed == "UNPRINTED") {
                    printed = false;
                }

                this.$axios.get(
                    "qrcode",
                    {
                        params: {
                            pageNo: this.pageNo,
                            pageSize: this.pageSize,
                            mark: this.searchMark,
                            categoryPath: this.categoryPath,
                            used: used,
                            printed: printed
                        }
                    }
                ).then((response) => {
                    this.qrCodes = response.data.data;
                    this.totalPage = response.data.totalPage;
                }).catch((error) => {
                    console.error(error)
                });
            },
            selectChange() {
                let id = this.selectId;
                let j = 0
                for (; j < this.printRawData.length; j++) {
                    if (id == this.printRawData[j].id) {
                        this.printSetting = JSON.parse(this.printRawData[j].print);
                    }
                }
            },
            changeNo() {
                this.getData();
            },
            pageSizeChange() {
                this.pageNo = 1;
                this.getData();
            },
            batchGenNo() {
                if (this.addNum == null) {
                    this.errorMessage = "num required";
                    return;
                }
                if (isNaN(this.addNum)) {
                    this.errorMessage = "not a number";
                    return;
                }
                this.$axios.put(
                    "qrcode?",
                    {
                        "n": this.addNum,
                        "mark": this.mark,
                        "encrypted": this.encrypt
                    }
                ).then(() => {

                    this.dialog = false;
                    this.getData();
                }).catch((error) => {
                    console.error(error)
                });
            },
            search() {
                this.getData();
            },
            markedPrinted() {
                let qrCodes = this.qrCodes;
                var ids = [];
                for (var i = 0; i < qrCodes.length; i++) {
                    ids.push(qrCodes[i].id);
                }

                this.$axios.post(
                    "qrcode/print",
                    {
                        "list": ids
                    }
                ).then(() => {
                    this.getData();
                }).catch((error) => {
                    console.error(error)
                });
            }
        }
    }

</script>